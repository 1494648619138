import { observer } from "mobx-react-lite";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { Button, Icon, Container, Header, Input, Checkbox } from "semantic-ui-react";
import { BaseStoreContext } from "../../../app/store/baseStore";

interface IProps {
    id: number;
    agencyName: string;
    setModalOpen: Dispatch<SetStateAction<boolean>>;

}

const SendLoginInfoModal: React.FC<IProps> = ({ id, agencyName, setModalOpen }) => {
    const baseStore = useContext(BaseStoreContext);
    const { sendInfo, sending } = baseStore.userStore;
    const [newDate, setNewDate] = useState<string>(new Date().toDateString());
    const [sendToAll, setSendToAll] = useState(false);

    function handleSendLoginInfo() {
        sendInfo(id, newDate, sendToAll);
        setModalOpen(false)
    }

    return (
        <Container textAlign='center' style={{ padding: 20 }}>
            <Header color="blue" icon='send' content={"Envoyer les infos de connexion"} style={{ marginBottom: 20 }} />

            <b>Séléctionnez les clients du réseau  {agencyName}  qui vous souhaitez envoyer leurs informations de connexion
            </b>
            <Checkbox label="Tout le réseau" checked={sendToAll} style={{ margin: "30px 10px" }} onChange={() => setSendToAll(!sendToAll)} />
            <div style={{ display: sendToAll ? "none" : "inline-table", marginTop: 20, marginBottom: 20, width: 200 }}>
                <Input name='creationDate' onChange={(e: any) => setNewDate(e.target.value)} value={newDate} type='date' />
            </div>
            <Button.Group fluid>
                <Button
                    style={{ width: 100 }}
                    color="red"
                    onClick={() => { setModalOpen(false); }}>
                    <Icon name='remove' /> Annuler
                </Button>
                <Button.Or text='Ou' />
                <Button
                    name={id}
                    style={{ width: 100 }}
                    color='green'
                    loading={sending}
                    onClick={(e) => {
                        handleSendLoginInfo()
                    }}>
                    <Icon name='checkmark' /> Confirmer
                </Button>
            </Button.Group>
        </Container>

    );
}

export default observer(SendLoginInfoModal);