import React, { useContext, useEffect, useState } from 'react';
import { GridColumn, Grid, Button, Container, Table, TableHeaderCell, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { BaseStoreContext } from '../../app/store/baseStore';
import Breadcrumbs from '../dashboard/breadcrumbs';
import { IInventoryCompany } from '../../app/models/IInventory';
import AsyncSelect from 'react-select/async';
import { RouteComponentProps } from 'react-router-dom';
import { FaCloudDownloadAlt, FaCloudUploadAlt, FaEye, FaInfoCircle } from 'react-icons/fa';
import UploadExcelCompanyProduct from './UploadExcelCompanyProduct';
import { MdDeleteForever } from 'react-icons/md';
import CloseInventoryModal from './CloseInventoryModal';
import VerifyProductsModal from './VerifyProductsModal';
import ListItemPlaceholder from '../../app/common/form/ListItemPlaceholder';

interface IDetailParams {
    id: string;
}

const InventoryProductDashboard: React.FC<RouteComponentProps<IDetailParams>> = ({ match }) => {
    const baseStore = useContext(BaseStoreContext);
    const { addInventoryCompany, InventoryCompanyList, adding, loadCompanyInventories, downloading, closing, uploading, downloadInventoryCompanies,
        loadingInventory, loadingInventoryCompanies, loadInventory, Inventory, confirmInventoryProducts, confirming, deleteInventoryCompanies, deleting, showBtnConfirm } = baseStore.inventoryStore;
    const [codeFranchiseur, setCodeFranchiseur] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<number>();
    const [inventoryCompany, setInventoryCompany] = useState<IInventoryCompany>();
    const { openModal } = baseStore.modalStore;
    const [modalOpen, setModalOpen] = useState(false);

    const handleAdd = () => {
        var invC: Partial<IInventoryCompany> = {
            companyId: parseInt(codeFranchiseur!),
            inventoryId: parseInt(match.params.id)
        };
        addInventoryCompany(invC)
    }

    useEffect(() => {
        loadInventory(parseInt(match.params.id));
        loadCompanyInventories(parseInt(match.params.id));
    }, []);

    return (

        <div>
            <Breadcrumbs
                items={[
                    { key: 0, name: "Inventaires", root: "" },
                    { key: 1, name: "Liste des inventaires", root: "/inventaire" },
                    { key: 2, name: "Inventaire detail", root: "" },
                ]}
            />
            <Grid>

                {!loadingInventory && !Inventory?.isClosed &&
                    <Container style={{ padding: 20, background: 'white', marginTop: 10, borderRadius: 5 }}>
                        <Grid>
                            <span style={{ fontSize: 18, fontWeight: 700, color: "#db2828" }}>Ajouter un inventaire pour le réseau</span>
                            <div style={{ width: 300 }}>
                                <AsyncSelect

                                    name="form-field-name"
                                    noOptionsMessage={() => "Code introuvable"}
                                    cacheOptions
                                    defaultOptions
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    loadOptions={baseStore.franchiseurStore.loadFranchiseurOptions}
                                    onChange={e => { setCodeFranchiseur(e!.key) }}
                                />
                            </div>
                            <Button content="Ajouter" onClick={handleAdd} positive loading={adding} />
                            {!Inventory?.isClosed &&
                                <Button
                                    color="orange"
                                    loading={uploading} onClick={(e) => {
                                        openModal(<UploadExcelCompanyProduct id={0} inventoryId={parseInt(match.params.id)} />)
                                    }}>
                                    <FaCloudDownloadAlt style={{ fontSize: "20px", paddingTop: 5, color: "white", cursor: "pointer" }} /> Importer
                                </Button>
                            }
                            {showBtnConfirm &&
                                <Button
                                    basic
                                    positive
                                    content="confirmer"
                                    floated="right"
                                    loading={confirming}
                                    onClick={(e) => confirmInventoryProducts(InventoryCompanyList, parseInt(match.params.id))}
                                />
                        }
                        {!showBtnConfirm && InventoryCompanyList.some((x) => (x.inventoryProducts!.length > 0))  &&
                            <Button content="Clôturer" onClick={() => openModal(<CloseInventoryModal id={parseInt(match.params.id)} />)} positive loading={closing} />
                        }
                    </Grid>
                    </Container>
                }
                <GridColumn width={8} style={{ marginTop: 2, paddingRight: 0, paddingLeft: 0 }}>
                    {loadingInventoryCompanies ?
                        <div style={{marginLeft:40}}>                   
                                <ListItemPlaceholder />
                        </div>
                        :
                        <Grid style={{ marginTop: 2, overflowY: "auto", height: 440 }}>
                            {InventoryCompanyList.map((inv: IInventoryCompany) => (
                                <>
                                    <Grid.Row columns={16} className="ui grid stackable" style={{ paddingBottom: 0 }}>
                                        <>
                                            <div className="ui segment" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", width: '100%', margin: '0 5% 0 10%', padding: 5, background: "rgb(108 111 127 / 83%)", height: 55 }} onClick={() => setInventoryCompany(inv)}>
                                                <div className="ui statistic" style={{ height: "auto" }}>
                                                    <div className="text value" style={{ display: "flex" }}>
                                                        <span style={{ fontSize: "18px", color: 'white', marginRight: 20, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "300px" }}>
                                                            <span style={{ fontSize: "18px", color: 'white', marginRight: 20 }}>{inv.agencyName} - {inv.companyName}</span>
                                                        </span>
                                                        <Button.Group style={{ position: "absolute", right: 10 }}>
                                                            <Button
                                                                style={{ border: 'none', padding: 0, margin: "0 2px", width: 40, height: 40, borderRadius: "50%", backgroundColor: "white" }} className="BackGroundWhite" loading={downloading && editTarget == inv.id} onClick={(e) => { setEditTarget(inv.id); downloadInventoryCompanies(inv.id, inv.agencyName + " " + inv.companyName) }}>
                                                                {!(downloading && editTarget == inv.id) &&
                                                                    <FaCloudUploadAlt style={{ fontSize: "27px", color: "green", cursor: "pointer" }} />
                                                                }
                                                            </Button>
                                                            {/*{!Inventory?.isClosed &&*/}
                                                            {/*    <Button*/}
                                                            {/*        style={{ border: 'none', padding: 0, margin: "0 2px", width: 40, height: 40, borderRadius: "50%", backgroundColor: "white!important" }} className="BackGroundWhite" loading={uploading && editTarget == inv.id} onClick={(e) => { setEditTarget(inv.id); openModal(<UploadExcelCompanyProduct id={inv.id} />) }}>*/}
                                                            {/*        <FaCloudDownloadAlt style={{ fontSize: "27px", color: "#ff4900", cursor: "pointer" }} />*/}
                                                            {/*    </Button>*/}
                                                            {/*}*/}

                                                            {!Inventory?.isClosed &&
                                                                <Button
                                                                    style={{ border: 'none', padding: 0, margin: "0 2px", width: 40, height: 40, borderRadius: "50%", backgroundColor: "white" }} className="BackGroundWhite"
                                                                    loading={deleting && editTarget == inv.id} onClick={(e) => { setEditTarget(inv.id); deleteInventoryCompanies(inv.id) }}>
                                                                    <MdDeleteForever style={{ fontSize: "27px", color: "#f12424", cursor: "pointer" }} />
                                                                </Button>
                                                            }
                                                            <Button
                                                                style={{ border: 'none', padding: 0, margin: "0 2px", width: 40, height: 40, borderRadius: "50%", backgroundColor: "white" }} className="BackGroundWhite" onClick={() => setInventoryCompany(inv)}>
                                                                <FaEye style={{ fontSize: "27px", color: inv.isVerified == 1 ? 'green' : inv.isVerified == 0 ? 'gray' : '#ffb100', cursor: "pointer" }} />
                                                            </Button>
                                                        </Button.Group>

                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    </Grid.Row>
                                </>

                            ))}
                        </Grid>
                    }
                </GridColumn>
                <GridColumn width={8} style={{ marginTop: 2, paddingRight: 0, paddingLeft: 0 }}>
                    {inventoryCompany ?
                        (
                            <Grid>
                                <Grid.Row columns={16} className="ui grid stackable">
                                    <>
                                        <div className="ui segment" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", width: '100%', margin: '0 10% 0 5%', padding: 10 }}>
                                            <div className="ui statistic" style={{ height: 30 }}>
                                                <div className="text value" style={{ display: "flex" }}>
                                                    <span style={{ fontSize: "18px", color: 'grey', marginRight: 20, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", width: "350px" }}>
                                                        <span style={{ fontSize: "18px", color: 'grey' }}>{inventoryCompany.agencyName} - {inventoryCompany.companyName}</span>
                                                    </span>
                                                    <Button style={{ border: 'none', margin: "0 2px", padding: 0, width: 30, height: 30 }} basic loading={downloading && editTarget == inventoryCompany.id} onClick={(e) => { setEditTarget(inventoryCompany.id); downloadInventoryCompanies(inventoryCompany.id, inventoryCompany.agencyName + " " + inventoryCompany.companyName) }}>
                                                        {!(downloading && editTarget == inventoryCompany.id) &&
                                                            <FaCloudUploadAlt style={{ float: "right", fontSize: "27px", color: "green", cursor: "pointer" }} />
                                                        }
                                                    </Button>
                                                    {!Inventory?.isClosed &&
                                                        <Button style={{ border: 'none', margin: "0 2px", padding: 0, width: 30, height: 30 }} basic loading={uploading && editTarget == inventoryCompany.id} onClick={(e) => {
                                                            setEditTarget(inventoryCompany.id); openModal(<UploadExcelCompanyProduct id={inventoryCompany.id} inventoryId={parseInt(match.params.id)} />)
                                                        }}>
                                                            <FaCloudDownloadAlt style={{ float: "right", fontSize: "27px", color: "#ff4900", cursor: "pointer" }} />
                                                        </Button>
                                                    }
                                                    {inventoryCompany.isVerified == 2 &&
                                                        < FaInfoCircle style={{ float: "right", fontSize: "27px", color: "#ffb100", cursor: "pointer" }} onClick={(e) => { setModalOpen(true) }} />
                                                    }
                                                    <Modal
                                                        open={modalOpen}
                                                        size='large'
                                                        closeOnEscape={true}
                                                        closeOnRootNodeClick={true}
                                                    >
                                                        <Modal.Header icon='add' >
                                                            Liste articles
                                                            <Button
                                                                style={{ height: 35 }}
                                                                type='button' icon='x' color='red'
                                                                floated='right'
                                                                onClick={() => { setModalOpen(false) }}
                                                            >
                                                            </Button>
                                                        </Modal.Header>
                                                        <Modal.Content>
                                                            <VerifyProductsModal setModalOpen={setModalOpen} inventoryProducts={inventoryCompany.inventoryProducts!.filter(x => x.isDirectSale == true || x.isNotExist == true)} />
                                                        </Modal.Content>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                        {inventoryCompany.inventoryProducts!.length > 0 ?
                                            (<div className="ui segment" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", width: '80%', margin: '0 8%', padding: 10, marginTop: 10, overflowY: "auto", height: 350 }}>
                                                <Table>

                                                    <Table.Header>
                                                        <TableHeaderCell>Produit</TableHeaderCell>
                                                        <TableHeaderCell>QT Réel</TableHeaderCell>
                                                        <TableHeaderCell>Valeur unit.</TableHeaderCell>
                                                        <TableHeaderCell>Prix vente</TableHeaderCell>
                                                    </Table.Header>
                                                    <Table.Body>
                                                        {inventoryCompany.inventoryProducts!.filter(x => x.isDirectSale == false && x.isNotExist == false).map((products) => (
                                                            <Table.Row>
                                                                <Table.Cell>
                                                                    <span style={{ fontSize: "18px", color: 'grey' }}>{products.productId} - {products.productName}{products.productAttribute != "" &&
                                                                        <b>: {products.productAttribute}</b>}</span><br />
                                                                </Table.Cell>
                                                                <Table.Cell>{products.stock_reel}</Table.Cell>
                                                                <Table.Cell>{products.valeur.toFixed(4)} €</Table.Cell>
                                                                <Table.Cell>{products.prix_vente.toFixed(4)} €</Table.Cell>
                                                            </Table.Row>
                                                        ))
                                                        }
                                                    </Table.Body>

                                                </Table>
                                                {showBtnConfirm &&
                                                    <Button
                                                        basic
                                                        positive
                                                        content="confirmer"
                                                        floated="right"
                                                        loading={confirming && editTarget == inventoryCompany.id}
                                                        onClick={(e) => confirmInventoryProducts([inventoryCompany], parseInt(match.params.id))}
                                                    />
                                                }
                                            </div>) : (
                                                <div className="ui segment" style={{ width: '80%', margin: '10px 8%', background: "#ff00000a", borderRadius: 5, border: "1px dashed red", height: "400px", }}>
                                                    <span style={{ fontSize: 18, color: "red", fontFamily: "sans-serif", top: 200, position: "absolute", paddingLeft: 20 }}> Importer les inventaires des produits</span>
                                                </div>
                                            )
                                        }
                                    </>
                                </Grid.Row>
                            </Grid>

                        ) : (
                            <Grid >
                                <Grid.Row >
                                    <GridColumn style={{ background: "#ff00000a", borderRadius: 5, border: "1px dashed red", height: "440px", margin: "0 10% 0 5%" }}>
                                        <span style={{ fontSize: 18, color: "red", fontFamily: "sans-serif", top: 250, position: "absolute" }}> Séléctionner un réseau pour afficher ces produits</span>
                                    </GridColumn>
                                </Grid.Row>
                            </Grid>
                        )
                    }
                </GridColumn>
            </Grid>
        </div>
    );
}
export default observer(InventoryProductDashboard);