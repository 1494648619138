import { BaseStore } from "./baseStore";
import { observable, action, runInAction, computed } from 'mobx';
import { IUserFromValues, IUser, IUserCompany, IPassword } from '../models/IUser';
import agent from "../agent/agent";
import { history } from '../../index';
import { toast } from "react-toastify";
import { IUserClaims } from "../models/IUsersRoles";

export default class UserStore {

    refreshTokenTimeOut: any;

    _baseStore: BaseStore;
    constructor(baseStore: BaseStore) {
        this._baseStore = baseStore;
    }

    @observable user: IUser | null = null;
    @observable loadingInitial = false;
    @observable submitting = false;
    @observable loadingIsMain = false;
    @observable sending = false;
    @observable utilisateurs: IUserCompany[] = [];
    @observable utilisateur: IUserCompany | null = null;
    @observable loadingUserAction = false;

    @computed get isLoggedIn() {
        return !!this.user;
    }

    @action login = async (values: IUserFromValues) => {
        try {
            const result = await agent.userAgent.login(values);
            this._baseStore.commonStore.setToken(result.token);
            this.startRefreshTokenTimer(result);
            runInAction(() => this.user = result);
            this._baseStore.modalStore.closeModal();
            history.push('/menuPage');
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    @action register = async (values: IUserFromValues) => {
        try {
            await agent.userAgent.register(values);
            history.push(`/user/registerSuccess?email=${values.email}`);
        } catch (e) {
            console.log(e);
            throw e;
        }
    }

    @action logout = () => {
        this._baseStore.commonStore.setToken(null);
        this.user = null;
        history.push('/');
    }

    @action getUser = async () => {
        console.log('getuser')
        try {
            const user = await agent.userAgent.current();
            runInAction(() => {
                this.user = user;
                const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
                localStorage.setItem("userRoles", jwtToken.role);
            });
            this.startRefreshTokenTimer(user);
        } catch (error) {
            console.log(error);
        }
    }

    @action getUserClaims = async () => {
        console.log('getUserClaims')
        try {
            const userClaims = await agent.usersRolesAgent.getUserClaims();
            sessionStorage.setItem("userClaimsSession", JSON.stringify(userClaims));
        } catch (e) {
            console.log(e);
        }
    }

    @action refreshToken = async () => {
        this.stopRefreshTokenTimer();
        try {
            const user = await agent.userAgent.refreshToken();
            runInAction(() => this.user = user);
            this._baseStore.commonStore.setToken(user.token);
            this.startRefreshTokenTimer(user);
        } catch (e) {
            console.log(e);
        }
    }

    private startRefreshTokenTimer(user: IUser) {
        
        const jwtToken = JSON.parse(atob(user.token.split('.')[1]));
        const expired = new Date(jwtToken.exp * 1000);
        const timeOut = expired.getTime() - Date.now();
        this.refreshTokenTimeOut = setTimeout(this.refreshToken, timeOut);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeOut);
    }

    @action setMainContactUser = async (user: IUserCompany) =>  {
        this.loadingIsMain = true;
        try {
            await agent.userAgent.setMainContact(user.id,user);
            runInAction(() => {
                this.utilisateurs.find(x => x.id == user.id)!.isMainContact = user.isMainContact;
                toast.success(user.isMainContact ? "L'utilisatuer " + user.user.lastName + ' à été affecté comme un contact principal' : "L'option contact principal à étét prélevé pour l'utilisateur " + user.user.lastName);
                this.loadingIsMain = false;
            });
        } catch (e) {
            this.loadingIsMain = false;
            console.log(e);
        }
    }

    @action loadUser = async (id: number) => {
        this.loadingInitial = true;
        try {
            const utilisateurs = await agent.userAgent.getuser(id);
            runInAction(() => {
                this.utilisateurs = utilisateurs;
                this.loadingInitial = false;
            })
        } catch (e) {
            runInAction(() => {
                this.loadingInitial = false;
            })
            console.log(e);
        }
    }
    @action createUser = async (values: IUserCompany) => {
        this.submitting = true;
        try {
            await agent.userAgent.create(values);
            runInAction(() => {
                this.utilisateurs.push(values);
                this.submitting = false;
                this.loadingInitial = true;
                toast.success("ajout effectué avec succès");
            })
        } catch (e) {
            toast.error("Problème d'ajout utilisateur!");
            runInAction(() => {
                this.submitting = false;
                console.log(e);
            })
        }
    }
    @action editUser = async (values: IUserCompany) => {
        this.submitting = true;
        try {
             await agent.userAgent.update(values);
            runInAction(() => {
                this.utilisateurs = this.utilisateurs.filter(x => x.id != values.id);
                this.utilisateurs.push(values);
                this.submitting = false;
                this.loadingInitial = true;
                toast.success("utilisateur modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }
    @action editPassword = async (values: IPassword) => {
        this.submitting = true;
        try {
            await agent.userAgent.updatePassword(values);
            runInAction(() => {
                this.submitting = false;
                toast.success("mot de passe modifié avec succès");
            })
        } catch (e) {
            runInAction(() => {
                this.submitting = false;
            })
            toast.error("Error submitting Data");
        }
    }
    @action deleteUser = async (id: number,userCompany:string) => {
        this.loadingUserAction = true;
        var users=await agent.userAgent.getuser(id);
        try {
            var user = users.find(x => x.id == userCompany);
            await agent.userAgent.delete(user!.id);
            runInAction(() => {
                this.loadingUserAction = false;
                this.utilisateurs = this.utilisateurs.filter(a => a.id !== userCompany);
                toast.success("suppression éffectuée avec succès");
            })
        } catch (e) {
            console.log(e);
            toast.error("Problème de suppréssion d'utilisateur!");
            runInAction(() => {
                this.loadingUserAction = false;
            })
        }

    }

    @action sendInfo = async (id: number, CreationDate: string, toAll: boolean) => {
        this.sending = true;
        try {
            await agent.userAgent.sendInfo(id, CreationDate, toAll);
            runInAction(() => {
                this.sending = false;
                toast.success("Les mails sont envoyés avec succès!!");
            })
        } catch (e) {
            runInAction(() => {
                this.sending = false;
            })
            toast.error("Problème d'envoi Email");
        }
    }
}