import React, { useContext, useEffect, useState } from 'react';
import { Form, Item, Table, Button, Header, Progress, FormInput, Grid, GridColumn } from 'semantic-ui-react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Loading from '../../../app/layout/Loading';
import { observer } from 'mobx-react-lite';
import TextInput from '../../../app/common/form/inputText';
import DeleteFamilleModal from './DeleteFamilleModal';
import FamilleForm from './AddFamilleModal';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../dashboard/breadcrumbs';
import ListItemPlaceholder from '../../../app/common/form/ListItemPlaceholder';
import InfiniteScroll from 'react-infinite-scroller';

const ListFamille: React.FC = () => {
    const baseStore = useContext(BaseStoreContext);
    const { loadFamilles, loadingInitial, edit, familles, loadingFamilleAction, progress, submitting, setPredicate, page, totalPages, setPage } = baseStore.familleStore;
    const [deleteTarget, setDeleteTarget] = useState<string | undefined>(undefined);
    const [editTarget, setEditTarget] = useState<string | undefined>(undefined);
    const [editForm, setEditForm] = useState(false);
    const [libelle, setLibelle] = useState<string | undefined>(undefined);
    const [desc, setDesc] = useState<string | undefined>(undefined);
    const [searchResults, setSearchResults] = useState([]);
    const { openModal } = baseStore.modalStore;
    const [loadingNext, setLoadingNext] = useState(false);
    const handleGetNext = () => {
        setLoadingNext(true);
        setPage(page + 1);
        loadFamilles().then(() => setLoadingNext(false));
    }
    const handleChange = (event: any) => {

        setSearchResults(event.target.value);
        if (event.target.value.length > 2) {
        setPredicate('search', event.target.value)
    }
        if (event.target.value.length === 0)
            setPredicate('all', 'true')

    }
    const handleFinalFormSubmit = () => {
        let newFamille = {
            libelle: libelle,
            description: desc ?? libelle,
            familleId: editTarget,
        };
        edit(newFamille);
        setEditForm(false)
    }
    useEffect(() => {
        loadFamilles();
    }, []);


    return (
        <div >
            <Breadcrumbs
                items={[
                    { key: 0, name: "Articles", root: "" },
                    { key: 1, name: "Familles", root: "/dashboardFamille" },
                ]}
            />
            <div style={{ margin: '2rem 1rem' }}>
                <Item.Group>
                    <Item>
                        <Item.Content>
                            <Header as='h2' icon='address card outline'></Header>
                            <Header as='h2'>{'Liste des familles des articles'}</Header>
                            <Button
                                floated="right"
                                content={baseStore.familleStore.addFamille ? 'Annuler' : "Ajouter une famille d'article"}
                                color="orange"
                                icon='arrow alternate circle left outline'
                                onClick={() => { baseStore.familleStore.changeButtonStateAdd(!baseStore.familleStore.addFamille) }}
                            />
                        </Item.Content>

                    </Item>
                </Item.Group>
                {baseStore.familleStore.addFamille ? (
                    <div>
                        <FamilleForm />
                        <br />
                    </div>
                ) : (
                    <Item.Group>
                        <Item>
                            <FormInput
                                icon='search'
                                type="text"
                                placeholder="Rechercher Famille"
                                value={searchResults}
                                onChange={handleChange}
                            />
                        </Item>
                    </Item.Group>)}
                {submitting &&
                    <Progress color='orange' percent={progress} size='tiny' />
                }
                {loadingInitial && page === 0 ? <ListItemPlaceholder /> : (               
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleGetNext}
                        hasMore={!loadingNext && page + 1 < totalPages}
                        initialLoad={false}
                    >
                    <Table loading={false} celled>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Libelle</Table.HeaderCell>
                                <Table.HeaderCell>Utilisation</Table.HeaderCell>
                                <Table.HeaderCell>Sous Famille</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>
                           
                            <Table.Body>
                             
                                {familles && familles.map((famille: any) => (
                                    <Table.Row key={famille.familleId}>
                                        {editForm && editTarget === famille.familleId ? (
                                            <>
                                                <Table.Cell selectable>
                                                    <Form.Input name='libelle' component={TextInput} placeholder='Libelle' defaultValue={famille.libelle} style={{ width: '100%', padding: '1%' }} onChange={event => setLibelle(event.target.value)} />
                                                </Table.Cell>
                                                <Table.Cell >
                                                </Table.Cell>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell >
                                                    <Button.Group fluid widths={2}>
                                                        <Button
                                                            type='button'
                                                            onClick={() => {
                                                                setEditForm(false);
                                                            }}>
                                                            Annuler
                                                        </Button>
                                                        <Button.Or />
                                                        <Button
                                                            name={famille.familleId}
                                                            positive
                                                            type='submit'
                                                            onClick={(e) => {
                                                                setEditTarget(e.currentTarget.name);
                                                                handleFinalFormSubmit();
                                                            }}
                                                        >Confirmer</Button>
                                                    </Button.Group>
                                                </Table.Cell>
                                            </>
                                        ) : (
                                            <>
                                                <Table.Cell>{famille.libelle}</Table.Cell>
                                                <Table.Cell>{famille.usedCount}</Table.Cell>
                                                <Table.Cell >
                                                    <Button.Group fluid widths={3}>
                                                        <Button
                                                            name={famille.familleId}
                                                            content={"sous famille"}
                                                            basic
                                                            color='orange'
                                                            icon='arrow alternate circle left outline'
                                                            as={Link}
                                                            to={`/dashboardSousFamille/${famille.familleId}`}
                                                        />
                                                    </Button.Group>
                                                </Table.Cell>
                                                <Table.Cell >
                                                    <Button.Group fluid widths={3}>
                                                        <Button
                                                            onClick={(e) => {
                                                                setEditForm(true);
                                                                setEditTarget(e.currentTarget.name)
                                                                setLibelle(famille.libelle)
                                                                setDesc(famille.description)
                                                            }}
                                                            color="blue"
                                                            icon='edit outline'
                                                            style={{ marginRight: '3%' }}
                                                            name={famille.familleId}
                                                        />
                                                        <Button
                                                            name={famille.familleId}
                                                            basic
                                                            color='red'
                                                            icon='trash'
                                                            onClick={(e) => {
                                                                openModal(<DeleteFamilleModal famille={famille} />)
                                                                setDeleteTarget(e.currentTarget.name)
                                                            }}
                                                            loading={loadingFamilleAction && deleteTarget === famille.familleId}
                                                        />
                                                    </Button.Group>
                                                </Table.Cell>
                                            </>
                                        )}
                                    </Table.Row>
                                ))}
                                  
                                </Table.Body>
                       
                        </Table>
                    </InfiniteScroll>
                )}

            </div>
        </div>
    );
}
export default observer(ListFamille);