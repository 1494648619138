import { Field, Form as FinalForm } from 'react-final-form';
import { observer } from 'mobx-react-lite';
import { IFacture } from '../../../app/models/IFacture';
import TextInput from '../../../app/common/form/inputText';
import { Form, Button, Grid, Input } from 'semantic-ui-react';
import { useContext, useEffect, useState } from 'react';
import { BaseStoreContext } from '../../../app/store/baseStore';
import Select from 'react-select';
import { toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';

interface IProps {
    editFacture: (facture: Partial<IFacture>) => void;
    facture: IFacture;
    submitting: boolean;
}

const FactureEditform: React.FC<IProps> = ({ editFacture, facture, submitting }) => {
    const baseStore = useContext(BaseStoreContext)
    const [dateFacture, setDateFacture] = useState<any>(new Date(facture.invoiceDate).toISOString().split('T')[0]);
    const [dueDate, setDueDate] = useState<any>(new Date(facture.dueDate).toISOString().split('T')[0]);
    const { loadModeReglements, modeReglements } = baseStore.modeReglementStore;
    const { checkDate } = baseStore.factureStore;
    const [modePaiement, setModePaiement] = useState(facture!.paymentMode);
    const [modeReglement, setModeReglement] = useState<any>(facture.paymentDelay);
    const { loadModePaiements, modePaiements, loadingInitial } = baseStore.modePaiementStore;
    const [searchResultsClient, setSearchResultsClient] = useState<any>(facture.agencyName);
    const [selectedLabelClient, setSelectedLabelClient] = useState<any>(facture.agencyName + "-" + facture.companyName);
    const handleChangeClient = (value: any) => {
        setSearchResultsClient(value.agencyName);
        setSelectedLabelClient(value!.agencyName + "-" + value!.companyName);
    }

    useEffect(() => {
        loadModePaiements();
        loadModeReglements();
    }, [])

    const handleChangeInvoiceDate = (value: any) => {
        var date = value.target.value;
        checkDate(date).then(
            result => {
                if (result == true) {
                    setDateFacture(date);
                }
                else {
                    toast.warning("Vous ne pouvez pas mettre cette date car ce mois a été cloturé")
                    setDateFacture(new Date(facture.invoiceDate).toISOString().split('T')[0]);
                }
            });
    }

    const handleFinalFormSubmit = (values: any) => {
        let newDevis: IFacture = {
            ...values,
            invoiceDate: dateFacture,
            dueDate: dueDate,
            paymentMode: modePaiement,
            paymentDelay: modeReglement,
            agencyName: searchResultsClient,
        };
        editFacture(newDevis);
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            initialValues={facture!}
            render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} error>
                    <Grid columns={2} divided className="WithoutSpace">
                        {!facture.isClosed &&
                            <Grid.Row>
                                <Grid.Column>
                                    <h5>Client</h5>
                                    <AsyncSelect
                                        name="form-field-name"
                                        noOptionsMessage={() => "Code introuvable"}
                                        cacheOptions
                                        defaultOptions
                                        placeholder={selectedLabelClient}
                                        value={searchResultsClient}
                                        getOptionLabel={e => e!.agencyName + "-" + e!.companyName}
                                        loadOptions={baseStore.clientStore.loadClientOptions}
                                        onChange={handleChangeClient}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        }
                        <Grid.Row>
                            <Grid.Column style={{ paddingBottom: 0, display: facture.invoiceType == "Facture_Avoir" ? "none" : "block" }}>
                                <h5>Mode de paiement</h5>
                                <Select
                                    isLoading={loadingInitial}
                                    options={modePaiements}
                                    getOptionLabel={e => e!.label.toString()}
                                    getOptionValue={e => e!.paiementId.toString()}
                                    onChange={e => { setModePaiement(e?.label!); }}
                                    placeholder={facture!.paymentMode ?? "Séléctionnez ... "}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                                <h5>Délai de paiement</h5>
                                <Select
                                    isLoading={baseStore.modeReglementStore.loadingInitial}
                                    options={modeReglements}
                                    getOptionLabel={e => e!.label}
                                    getOptionValue={e => e!.label}
                                    onChange={e => { setModeReglement(e?.label); }}
                                    placeholder={modeReglement}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                                <h5>Date facture</h5>
                                <Input
                                    type="date"
                                    name="invoiceDate"
                                    value={dateFacture}
                                    onChange={(e) => handleChangeInvoiceDate(e)}
                                />
                            </Grid.Column>
                            <Grid.Column style={{ paddingBottom: 0 }}>
                                <h5>Date d'échéance</h5>
                                <Input
                                    type="date"
                                    name="dueDate"
                                    defaultValue={new Date(facture.dueDate).toISOString().split('T')[0]}
                                    onChange={(e) => setDueDate(e.target.value)}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                                <h5>Commentaire</h5>
                                <Field name='comment' component={TextInput} placeholder='Commentaire' defaultValue={facture!.comment} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                                <h5>Code général des impots</h5>
                                <Field name='TaxCode' component={TextInput} placeholder='TaxCode' defaultValue={facture!.taxCode} />
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <div style={{ marginTop: '20px' }}>
                        <Button.Group floated="right">
                            <Button disabled={submitting} onClick={() => baseStore.factureStore.changeButtonState(false)} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </div>
                </Form>
            )}
        />
    );
}
export default observer(FactureEditform);