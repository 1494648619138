import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Grid } from 'semantic-ui-react';
import { Field, Form as FinalForm } from 'react-final-form';
import { BaseStoreContext } from '../../../app/store/baseStore';
import { observer } from 'mobx-react-lite';
import { IArticle } from '../../../app/models/IArticle';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { ITarifFranchiseur } from '../../../app/models/ITarifFranchiseur';
import { IFacture, IInvoiceLine } from '../../../app/models/IFacture';
import InputTextArea from '../../../app/common/form/inputTextArea';
import { IArticleCompose } from '../../../app/models/IArticleCompose';
import { IItem, IItems } from '../../../app/models/IProductAttributes';
import { IAccountList } from '../../../app/models/IAccount';
import { IOptionList } from '../../../app/models/IOption';
import { v4 as uuid } from 'uuid';
import CreatableSelect from 'react-select/creatable';

interface IProps {
    facture: IFacture;
    invoiceLine: IInvoiceLine;
    setModalOpen: Dispatch<SetStateAction<boolean>>;
}
const handleSetAction = (list: string[]) => {
    const options: IAccountList[] = [];
    list.forEach((item, key) => {
        options.push({ key: key.toString(), text: item, value: item })
    });
    return options;
}
const EditFactureArticle: React.FC<IProps> = ({ facture, invoiceLine, setModalOpen }) => {

    const baseStore = useContext(BaseStoreContext);
    const { loadingOption, loadOptions, ITListOption, submittingOption, loadOptionsArticle, loadOptionsProduct, ITListOptionArticle } = baseStore.optionStore;
    const { loadArticleFranchiseurs, loadingArticle, articlesFranchiseur } = baseStore.articleStore;
    const { editInvoiceLine, submitting } = baseStore.factureStore;
    const { loadingtarifFranchiseur, loadtarifFranchiseurs, tarifFranchiseurs } = baseStore.tarifFranchiseurStore;
    const [minQuantite, setMinQuantite] = useState<number | null>(invoiceLine!.quantity!);
    const [validArticle, setValidArticle] = useState(true);
    const [article, setArticle] = useState<IArticle | undefined>();
    const [unitPrice, setUnitPrice] = useState<number>(invoiceLine.unitPrice!);
    const [unitPriceWithTaxes, setUnitPriceWithTaxes] = useState<number>(invoiceLine.unitPrice! + (invoiceLine.unitPrice! * invoiceLine.tvaRate! / 100));
    const [totalPrice, setTotalPrice] = useState<number>(invoiceLine.unitPrice! * invoiceLine.quantity! - invoiceLine.unitPrice! * invoiceLine.quantity! * invoiceLine.remise! / 100);
    const totalPriceTaxes = (invoiceLine.unitPrice! * invoiceLine.quantity! + (invoiceLine.unitPrice! * invoiceLine.tvaRate! / 100) * invoiceLine.quantity!);
    const [totalPriceWithTaxes, setTotalPriceWithTaxes] = useState<number>(totalPriceTaxes - totalPriceTaxes * invoiceLine.remise! / 100);
    const [quantity, setQuantity] = useState<number>(invoiceLine.quantity!);
    const [disabled, setDisabled] = useState(true);
    const [tarif, setTarif] = useState<ITarifFranchiseur>();
    const [tva, setTVA] = useState(invoiceLine.tvaRate);
    const [remise, setRemise] = useState<number>(invoiceLine.remise!);
    const [isService, setIsService] = useState(invoiceLine.isService);
    const [packProducts, setPackProducts] = useState<IArticleCompose[]>([]);
    const [articleId, setArticleId] = useState<number>(invoiceLine.productId!);
    const [attributes, setAttributes] = useState<IItems[]>([]);
    const [options, setOptions] = useState<Array<IOptionList>>([]);

    useEffect(() => {
        loadOptions();

        if (isService == true) {
            setMinQuantite(invoiceLine.quantity!);
            setDisabled(false);
            setValidArticle(false);
        }
        else {
            loadOptionsProduct(invoiceLine.productId!.toString()).then(data => {
                var finition: IOptionList[] = [];
                data.forEach((optionArticle) => {
                    let optionn: IOptionList = {
                        key: optionArticle.option.optionId,
                        label: optionArticle.option.libelle,
                        value: optionArticle.option.optionId
                    }
                    finition.push(optionn)
                })
                setOptions(finition);
            });
            loadtarifFranchiseurs(invoiceLine.productId!, facture.idCompany)
                .then((data: any) => {
                    const tarifFranchiseur = (data![data!.length - 1].quantite <= invoiceLine.quantity!) ? data![data!.length - 1] : data![data!.findIndex((x: ITarifFranchiseur) => x.quantite > invoiceLine.quantity!) - 1];
                    const minQuanity = data![0];
                    setMinQuantite(minQuanity.quantite);
                    setTarif(tarifFranchiseur)
                    setDisabled(false);
                    setValidArticle(false);
                    setArticle(tarifFranchiseur!.product);
                });
        }
        loadArticleFranchiseurs(facture!.idCompany!, facture!.typeClient!);
    }, [])

    const handleSetArticle = (result: any) => {
        setValidArticle(true);
        setArticleId(result.value);
        loadOptionsProduct(result.value.toString()).then(data => {
            var finition: IOptionList[] = [];
            data.forEach((optionArticle) => {
                let optionn: IOptionList = {
                    key: optionArticle.option.optionId,
                    label: optionArticle.option.libelle,
                    value: optionArticle.option.optionId
                }
                finition.push(optionn)
            })
            setOptions(finition);
        });
        setPackProducts(result.packProducts);
        if (result.isService) {
            setMinQuantite(1);
            setQuantity(1);
            setDisabled(false);
            setValidArticle(false);
            setUnitPrice(1);
            setUnitPriceWithTaxes(1 + (result.tvaRate / 100));
            setTotalPrice(1);
            setIsService(true);
            setArticle(undefined);
            setTotalPriceWithTaxes(1 + (result.tvaRate / 100));
            setTVA(result.tvaRate)
        }
        else {
            loadtarifFranchiseurs(result.value, facture!.idCompany)
                .then((data) => {
                    if (data!.length > 0) {
                        let resultTarif = data?.sort((a, b) => a.quantite - b.quantite)
                            .filter((item, index, array) => item.quantite === array[0].quantite)
                            .find(s => { return s; })
                        const minQ = resultTarif!.quantite;
                        const unitPriceByTarif = resultTarif?.prix;
                        const tauxTva = facture!.hasTva ? resultTarif?.product!.tvaRate! : 0;
                        setArticle(resultTarif?.product)
                        setMinQuantite(minQ);
                        setQuantity(minQ);
                        var res: IItems[] = [];
                        resultTarif?.product.productAttributes.forEach((item) => {
                            var x: IItem[] = [];
                            item.possibleValues.split(';').map((item) => {
                                x.push({ key: item, value: 0 });
                            });
                            res.push({ id: item.id, name: item.name, isMulti: item.displayAllWithDistinctQuantities, attributs: x });
                        });
                        setAttributes(res);
                        setUnitPrice(unitPriceByTarif!);
                        setTotalPrice(minQ! * unitPriceByTarif!);
                        setUnitPriceWithTaxes((unitPriceByTarif! * (100 + tauxTva)) / 100);
                        setTotalPriceWithTaxes((minQ! * unitPriceByTarif! * (100 + tauxTva)) / 100);
                        setDisabled(false);
                        setValidArticle(false);
                        setRemise(0);
                    } else {
                        setMinQuantite(0);
                        setQuantity(0);
                        setDisabled(true);
                        setUnitPrice(0);
                        setUnitPriceWithTaxes(0);
                        setTotalPrice(0);
                        setTotalPriceWithTaxes(0);
                        toast.error("cette article n'a pas de tarif");
                    }
                });
        }
    }

    const handleSetPrixByTypeandQuantite = (e: any) => {
        if (isService) {
            const parsedQuantite = parseFloat(e.target.value)
            setQuantity(parsedQuantite);
            const total = (parsedQuantite * unitPrice);
            const totalTaxes = (total) + (total * tva! / 100);
            setTotalPrice(total - total * remise / 100);
            setTotalPriceWithTaxes(totalTaxes - totalTaxes * remise / 100);
        }
        else {
            setValidArticle(true);
            const parsedQuantite = article?.incrementation ? parseFloat(e.target.value) : parseFloat(e.quantite);
            if (article?.incrementation == false) setTarif(e);

            if (parsedQuantite >= minQuantite!) {
                const tauxTva = facture!.hasTva ? article!.tvaRate! : 0;
                setQuantity(parsedQuantite);
                var data = (tarifFranchiseurs![tarifFranchiseurs!.length - 1].quantite <= parsedQuantite) ? tarifFranchiseurs![tarifFranchiseurs!.length - 1].prix : tarifFranchiseurs[tarifFranchiseurs!.findIndex((x: ITarifFranchiseur) => x.quantite > parsedQuantite) - 1].prix;
                setUnitPrice(data!);
                const total = parsedQuantite * data!;
                setTotalPrice(total - total * remise / 100);
                const taxePrice = (data! * (100 + tauxTva)) / 100;
                setUnitPriceWithTaxes(taxePrice);
                const totalTaxe = (total * (100 + tauxTva)) / 100;
                setTotalPriceWithTaxes(totalTaxe - totalTaxe * remise / 100);
                setValidArticle(false);
            } else {
                toast.error("Quantité minimal est " + minQuantite);
                setQuantity(0);
                setValidArticle(false);
            }
        }
    }
    const handleChangeOption = (inputValue: string) => {
        setTimeout(() => {
            var id = uuid();
            const newOption: IOptionList = {
                key: id,
                label: inputValue,
                value: inputValue
            }
            setOptions((prev) => [...prev, newOption]);
        }, 1000);
    }
    const handleSetInformation = (e: any) => {
        var value = e.target.value == "" ? 0 : parseFloat(e.target.value);
        const tauxTva = isService ? tva : facture!.hasTva ? article!.tvaRate! : 0;
        setUnitPrice(parseFloat(e.target.value));
        setUnitPriceWithTaxes(value + value * tauxTva! / 100);
        const total = (quantity! * e.target.value);
        const totalTaxes = (total) + (total * tva! / 100);
        setTotalPrice(total - total * remise / 100);
        setTotalPriceWithTaxes(totalTaxes - totalTaxes * remise / 100);
    }
    const handleChangePriceRemise = (e: any) => {
        const Remise = e.target.value;
        setRemise(Remise);
        setTotalPrice(unitPrice * quantity! - (unitPrice * quantity! * Remise / 100));
        setTotalPriceWithTaxes(unitPriceWithTaxes * quantity! - (unitPriceWithTaxes * quantity! * Remise / 100));
    }
    const handleSetAttribute = (e: any, id: number, key: any) => {
        var list: IItems[] = attributes;
        const attributs = list[list.findIndex(x => x.id == id)].attributs;
        list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.key == key)].value = parseInt(e.target.value);
        setAttributes(list);
    }
    const handleSetAttributeSelect = (id: number, key: any) => {
        var list: IItems[] = attributes;
        const attributs = list[list.findIndex(x => x.id == id)].attributs;
        const item = list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.value == 1)];
        if (item)
            list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.value == 1)].value = 0;
        list[list.findIndex(x => x.id == id)].attributs[attributs.findIndex(x => x.key == key)].value = 1;
        setAttributes(list);
    }
    const handleFinalFormSubmit = (values: any) => {
        var tab: string[] = [];
        options.map((item: any) => {
            tab.push(item.label)
        })
        var check = true;
        var list: IItem[] = [];
        attributes.forEach(item => {
            var som = 0;

            item.attributs.forEach(item => {
                som += item.value;
                list.push(item)
            });
            if (som != quantity)
                check = false;
        });
        if (!check)
            alert("Vous devez atteindre " + quantity + " unités cumulées");
        else {
            let newOrderLine: IInvoiceLine = {
                ...invoiceLine,
                ...values,
                quantity: quantity,
                productId: article?.productId! ?? articleId,
                unitPrice: unitPrice,
                unitPriceWithTaxes: (unitPriceWithTaxes),
                tvaRate: isService ? tva : facture!.hasTva ? article!.tvaRate : 0,
                label: article?.label!,
                id: invoiceLine.id,
                totalPrice: totalPrice,
                totalPriceWithTaxes: totalPriceWithTaxes,
                remise: remise,
                optionArticles: tab

            };
            editInvoiceLine(invoiceLine.id!, newOrderLine, newOrderLine.totalPriceWithTaxes!);
            setModalOpen(false);
        }
    }

    return (
        <FinalForm
            onSubmit={handleFinalFormSubmit}
            render={({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} error >
                    <Form.Group unstackable widths={2} >
                        <div className="field">
                            <h5>Article</h5>
                            <Select isSearchable name="article" isLoading={loadingArticle}
                                options={articlesFranchiseur}
                                getOptionLabel={e => e!.reference + ' - ' + e!.label}
                                getOptionValue={e => e!.productId.toString()}
                                placeholder={articlesFranchiseur.find(x => x.productId == articleId)?.label}
                                onChange={(e) => handleSetArticle(e!)}
                            />
                        </div>
                        <div className="field">
                            {packProducts && packProducts.map((product) => (
                                <div style={{ paddingTop: 10 }}><h5>{product.article} - {product.articleLibelle} / {product.quantite}</h5></div>
                            ))}
                        </div>
                    </Form.Group>
                    {article?.productAttributes && article?.productAttributes.map((productAttribute, key) => (
                        productAttribute.displayAllWithDistinctQuantities ? (
                            <div className="field">
                                <Grid>
                                    {productAttribute.possibleValues.split(';').map((item: string, index) => (
                                        <Grid.Column width={4} style={{ textAlign: "center" }}>
                                            <label style={{ width: '50%', marginTop: 5 }}>{item}</label>
                                            <input style={{ width: '50%', float: "right" }} type="number" min={0} defaultValue={0}
                                                onBlur={e => handleSetAttribute(e, productAttribute.id, item)} />
                                        </Grid.Column >
                                    ))}
                                </Grid>
                            </div>
                        ) : (
                            <div className="field">
                                <h5>{productAttribute.name}</h5>
                                <Select
                                    placeholder="Séléctionner un ... "
                                    options={handleSetAction(productAttribute.possibleValues.split(';'))}
                                    getOptionLabel={e => e!.text}
                                    getOptionValue={e => e!.value}
                                    onChange={e => handleSetAttributeSelect(productAttribute.id, e!.value)}
                                />
                            </div>
                        )
                    ))
                    }
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Quantité *</label>
                            {!validArticle ? (
                                isService || article?.incrementation ? (
                                    <Input name='quantiy' id='Qte' disabled={disabled} placeholder="Quantité" type='number' step="1" min='0' defaultValue={minQuantite?.toString()} onChange={e => handleSetPrixByTypeandQuantite(e)} />
                                ) : (
                                    <Select
                                        isLoading={loadingtarifFranchiseur}
                                        options={tarifFranchiseurs}
                                        getOptionLabel={e => e!.quantite.toString()}
                                        getOptionValue={e => e!.quantite.toString()}
                                        value={tarif}
                                        onChange={e => handleSetPrixByTypeandQuantite(e)}
                                    />
                                )) : (null)}
                        </div>
                        <div className="field">
                            <label style={{ marginTop: 32 }} color="grey">TVA : {facture!.hasTva ? isService ? tva : article?.tvaRate : " (Non assujéti.)"}</label>
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix unitaire</label>
                            <Input name='unitPrice' disabled={validArticle} placeholder="Prix unitaire" type='number' step="0.0001" min='0' defaultValue={unitPrice} onChange={handleSetInformation} />
                        </div>
                        <div className="field">
                            <label>Prix unitaire avec taxes</label>
                            <input name='unitPriceTaxes' disabled={true} value={unitPriceWithTaxes.toFixed(4)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Prix Total</label>
                            <input name='totalPrice' disabled={true} placeholder="Prix Total" value={totalPrice.toFixed(2)} />
                        </div>
                        <div className="field">
                            <label>Prix Total avec taxes</label>
                            <input name='totalPriceTaxes' disabled={true} value={totalPriceWithTaxes.toFixed(2)} />
                        </div>
                    </Form.Group>
                    <Form.Group unstackable widths={2}>
                        <div className="field">
                            <label>Commentaire</label>
                            <Field name='comment' rows={2} defaultValue={invoiceLine.comment} component={InputTextArea} placeholder="Commentaire" />
                        </div>
                        <div className="field" style={{ display: validArticle ? 'none' : 'block' }}>
                            <label>Remise</label>
                            <input name='remise' min={0} max={100} defaultValue={0} step={0.0001} value={remise} type="number" onChange={(e) => handleChangePriceRemise(e)} />
                        </div>
                    </Form.Group>
                    <Grid unstackable>
                        <Grid.Column width={8}>
                            {article?.paperFormat &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Format: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperFormat}</span></label>
                                    </div>
                                </Grid.Row>
                            }

                            {article?.printing &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Impression: <span style={{ fontSize: 14, color: 'grey' }}>{article?.printing}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {article?.paperWeight &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Support: <span style={{ fontSize: 14, color: 'grey' }}>{article?.paperWeight}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                            {article?.deliveryDelay &&
                                <Grid.Row width={16}>
                                    <div className="field">
                                        <label color="grey">Délais de livraison: <span style={{ fontSize: 14, color: 'grey' }}>{article?.deliveryDelay}</span></label>
                                    </div>
                                </Grid.Row>
                            }
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <div className="field">
                                <label color="grey">Poids unitaire: <span style={{ fontSize: 14, color: 'grey' }}>{article?.weight} Kg</span></label>
                            </div>
                            <div className="field">
                                <label> Finition </label>
                                <CreatableSelect
                                    isMulti
                                    isDisabled={loadingOption || submittingOption}
                                    isLoading={loadingOption || submittingOption}
                                    options={ITListOption}
                                    value={options}
                                    placeholder="Finition / Option"
                                    onChange={(option: any) => {
                                        setOptions(option);
                                    }}
                                    onCreateOption={handleChangeOption}
                                />

                            </div>
                        </Grid.Column>
                    </Grid>
                    <Form.Group unstackable style={{ marginLeft: "70%" }} >
                        <Button.Group floated="right">
                            <Button onClick={() => { setModalOpen(false) }} floated='right' type='button'>Annuler</Button>
                            <Button.Or />
                            <Button positive disabled={submitting || invalid || validArticle || invoiceLine.unitPrice!.toString() == "NaN"} loading={submitting} floated='right' type='submit'>Confirmer</Button>
                        </Button.Group>
                    </Form.Group>
                </Form>
            )}
        />
    );
}
export default observer(EditFactureArticle);
